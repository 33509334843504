import React from "react";
import FeaturedSlider from "./FeaturedSlider";

const FeaturedPlayers = () => {
  return (
    <section className=" mt-20">
      <h2 className="text-center font-Orbitron md:text-[40px] text-2xl font-bold text-green uppercase md:mb-8 mb-4">featured players</h2>
      <h2 className="text-center font-poppins md:text-[18px] text-xs text-white uppercase ">
        Follow and connect with popular <span className="text-green">gamers</span>
      </h2>
      <div className="">
        <FeaturedSlider />
      </div>
    </section>
  );
};

export default FeaturedPlayers;
