import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



import zeus from "../../assets/image/zeus1.png";
import zeus2 from "../../assets/image/zeus3.png";
import what from "../../assets/image/what.svg";
const Videonew = require("../../assets/ZEUSFINAL.mp4")
const HomeDetails = () => {
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
  return (
    <section>
      <div className="md:flex hidden justify-between md:space-x-0 space-x-8 w-full md:mt-32 mt-16">
        <div className="flex flex-col space-y-10 items-center w-4/12 text-right pb-[40px] relative z-1">
          <div className="w-full">
            <h3 className="font-Orbitron text-[24px] font-bold text-green md:pr-10">GAMERS</h3>
            <div className="font-poppins font-normal lg:text-[14px] md:text-[12px] leading-[24px] opacity-80 text-white homeDetails-text-bg left  ">
              <p className="md:w-[320px]">
                The first Exclusively Elite <span className="text-green">Gamer</span> network, spawned to connect
                mortals and make them Gods.
              </p>
            </div>
          </div>
          <div className="w-full">
            <h3 className="font-Orbitron text-[24px] font-bold text-green md:pr-10">TEAMS</h3>
            <div className="font-poppins font-normal lg:text-[14px] md:text-[12px] leading-[24px] opacity-80 text-white homeDetails-text-bg left">
              <p className="md:w-[320px]">
                Communication is Key, and <span className="text-green">GameGods</span> Offers it you you On Platform
              </p>
            </div>
          </div>
          <div className="w-full">
            <h3 className="font-Orbitron text-[24px] font-bold text-green md:pr-10">GUILDS</h3>
            <div className="font-poppins font-normal lg:text-[14px] md:text-[12px] leading-[24px] opacity-80 text-white homeDetails-text-bg left">
              <p className="md:w-[320px]">
                <span className="text-green">Create</span> and <span className="text-green">Own</span> your guild, and
                be a part of something bigger!
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-start items-center w-4/12 -mt-10">
          {/* <h2 className="font-Orbitron text-center text-green xl:text-[56px] lg:text-[46px] md:text-[40px] text-[40px] font-bold leading-[1.2] ">
            WHAT IS <br /> GAMEGODS?
          </h2> */}
          <img className="w-[70%] relative z-1" src={what}/>
          <div className="2xl:mt-5 mt-10 vid-grid relative z-0 2xl:h-[56%] xl:h-[56%] md:h-[50%] overflow-hidden">
            {/* <img className="h-[500px] w-full object-contain" src={zeus} alt="zeus" /> */}
            <video poster={zeus2} className="scale-[1.9] h-fit w-full m-auto object-top md:relative md:left-[30px] md:mt-[25%]" autoPlay muted loop>
              <source src={Videonew}/>
            </video>
          </div>
        </div>
        <div className="flex flex-col space-y-10 items-center w-4/12  pb-[40px] relative z-1">
          <div className="w-full">
            <h3 className="font-Orbitron text-[24px] font-bold text-green md:pl-10">NEWS</h3>
            <div className="font-poppins font-normal lg:text-[14px] md:text-[12px] leading-[24px] opacity-80 text-white homeDetails-text-bg right">
              <p className="md:w-[320px]">
                Stay up to date on what’s hot in Gaming, Blockchain and Esports with
                <span className="text-green"> GameGods </span> News
              </p>
            </div>
          </div>
          <div className="w-full">
            <h3 className="font-Orbitron text-[24px] font-bold text-green md:pl-10">GAMES</h3>
            <div className="font-poppins font-normal lg:text-[14px] md:text-[12px] leading-[24px] opacity-80 text-white homeDetails-text-bg right">
              <p className="md:w-[320px]">
                <span className="text-green ">Dominate</span> your foes in Casual, competitive or Play to Earn
                Blockchain Gaming.
              </p>
            </div>
          </div>
          <div className="w-full">
            <h3 className="font-Orbitron text-[24px] font-bold text-green md:pl-10">REWARDS</h3>
            <div className="font-poppins font-normal lg:text-[14px] md:text-[12px] leading-[24px] opacity-80 text-white homeDetails-text-bg right">
              <p className="md:w-[320px]">
                <span className="text-green">GameGods</span> Rewards you for completing various tasks on the platform.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden block w-full md:mt-28 mt-16">
        {/* <h2 className="text-center font-Orbitron md:text-[40px] text-[40px] font-bold text-green uppercase md:mb-8 mb-4">
          What is <br/> Gamegods?
        </h2> */}
        <img className="md:w-8/12 w-10/12 md:mb-8 mb-4 m-auto" src={what}/>
        <div className="mb-[21px] relative vid-grid overflow-hidden w-auto">
          {/* <img className="h-[224px] w-full object-contain m-auto" src={zeus} alt="zeus" /> */}
          <video poster={zeus2} className="h-[290px] w-full m-auto object-cover left-[30px] scale-[1.5] object-top md:top-0 top-[40px] relative" autoPlay muted loop>
              <source src={Videonew}/>
            </video>
        </div>
        <Slider {...settings2}>

          <div className="text-center px-8">
            <p className=" text-green font-Orbitron text-2xl font-bold mb-5">GAMERS</p>
            <p className="text-white font-poppins text-sm leading-[28px]">The first Exclusively Elite <span className="text-green">Gamer</span> network, spawned to connect mortals and make them Gods.</p>
          </div>

          <div className="text-center px-8">
            <p className=" text-green font-Orbitron text-2xl font-bold mb-5">NEWS</p>
            <p className="text-white font-poppins text-sm leading-[28px]">Stay up to date on what’s hot in Gaming, Blockchain and Esports with <span className="text-green">GameGods</span> News</p>
          </div>

          <div className="text-center px-8">
            <p className=" text-green font-Orbitron text-2xl font-bold mb-5">TEAMS</p>
            <p className="text-white font-poppins text-sm leading-[28px]">Communication is Key, and <span className="text-green">GameGods</span> Offers it you you On Platform</p>
          </div>

          <div className="text-center px-8">
            <p className=" text-green font-Orbitron text-2xl font-bold mb-5">GAMES</p>
            <p className="text-white font-poppins text-sm leading-[28px]"><span className="text-green">Dominate</span> your foes in Casual, competitive or Play to Earn Blockchain Gaming.</p>
          </div>

          <div className="text-center px-8">
            <p className=" text-green font-Orbitron text-2xl font-bold mb-5">GUILDS</p>
            <p className="text-white font-poppins text-sm leading-[28px]"><span className="text-green">Create</span> your own <span className="text-green">Community</span> that will Rule over all other Gods</p>
          </div>

          <div className="text-center px-8">
            <p className=" text-green font-Orbitron text-2xl font-bold mb-5">REWARDS</p>
            <p className="text-white font-poppins text-sm leading-[28px]"><span className="text-green">GameGods</span> Rewards you for completing various tasks on the platform.</p>
          </div>

        </Slider>
      </div>
    </section>
  );
};

export default HomeDetails;
