// import { Platform } from './Platform';
import { Pointer, Relation ,Object} from 'parse';
import { createRelation } from '../pkg/parse';
import { Collection } from './nft/collection';
import { Platform } from './platform';
import { Post } from './post';
import { Team } from './team';
import { User } from './user';

interface GameFields {
    name: string
    gameName: string
    bio: string

    platforms: Relation<Platform>
    platformObjs?: Platform[]

    bannerImage?: File
    avatarImage?: File

    followers: Relation<User>
    followersObjs?: User[]
    followersCount: number

    admins: Relation<User>
    adminsObjs?: User[]

    taggedPosts: Relation<Post>
    taggedPostsObjs?: Post[]
    taggedPostsCount: number

    isSeller: boolean
    nftCollections: Relation<Collection>
    nftCollectionsObjs?: Collection[]
    nftCollectionsCount: number

    teams: Relation<Team>
    teamsObjs?: Team[]
    teamsCount: number

    topPlatform?: Platform | Pointer

    isFollowing?: boolean

}

export class Game extends Object<GameFields> {
    constructor(fields = {
        name: '',
        bio: '',
        gameName: '',
        followers: createRelation<User>('_User', 'followers'),
        admins: createRelation<User>('_User', 'admins'),
        followersCount: 0,
        taggedPostsCount: 0,
        taggedPosts: createRelation<Post>('_User', 'taggedPosts'),
        platforms: createRelation<Platform>('Platform', 'platforms'),
        isSeller: false,
        nftCollections: createRelation<Collection>('NFTCollection', 'nftCollections'),
        nftCollectionsCount: 0,
        teams: createRelation<Team>('Team', 'teams'),
        teamsCount: 0,
    }) {
        super('Game', fields);
    }
}

Object.registerSubclass('Game', Game)