import { Route, Routes } from "react-router-dom";
import EdituserPge from "./ui/pages/UserEdit2";
import Login from "./ui/pages/AuthLogin";
import SignUpPage from "./ui/pages/AuthSignUp";
import Drop from "./ui/pages/Drop";
import GameProfile from "./ui/pages/GameProfile";
import GamerNFT from "./ui/pages/GamerNFT";
import GamerNftNew from "./ui/pages/GamerNftNew";
import GamerProfile from "./ui/pages/GamerProfile";
import Home from "./ui/pages/Home";
import { LandingPage } from "./ui/pages/HomeLanding";
import NftList from "./ui/pages/Nftlist";
import Teamlist from "./ui/pages/Teamlist";
import TeamNftDetails from "./ui/pages/TeamNftDetails";
import UserProfile from "./ui/pages/UserProfile";
import Privacy from "./ui/pages/Privacy";
import Terms from "./ui/pages/Terms";

export interface LocationStates {
  "/"?: {};
  "/#"?: {};
  "/signup"?: {};
  "/login"?: {};
  "/edituser"?: {};
  "/home2"?: {};
  "/drop"?: {};
  "/gamernft"?: {};
  "/gamernftnew"?: {};
  "/teamnftdetails"?: {};
  "/gamerprofile"?: {};
  "/gameprofile"?: {};
  //
  "/nft/:id"?: {};
  "/gamer/:id"?: {};
  "/team/:id"?: {};
  "/player-profile"?: {};
  "/teamlist"?: {};
  "/nftlist"?: {};
  "/userprofile"?: {};
  "/privacy"?: {};
  "/terms"?: {};
}

export type PathName = keyof LocationStates;

export interface Page {
  path: PathName;
  exact?: boolean;
  component: JSX.Element;
}

export const pages: Page[] = [
  // { path: "/", exact: true, component: < LandingPage /> },
  { path: "/", exact: true, component: <Home /> },
  // { path: "/#", exact: true, component: <Home /> },
  // { path: "/signup", exact: true, component: <SignUpPage /> },
  // { path: "/login", exact: true, component: <Login /> },
  // { path: "/edituser", exact: true, component: <EdituserPge /> },
  // { path: "/drop", exact: true, component: <Drop /> },
  // { path: "/gamernft", exact: true, component: <GamerNFT /> },
  // { path: "/gamernftnew", exact: true, component: <GamerNftNew /> },
  // { path: "/teamnftdetails", exact: true, component: <TeamNftDetails /> },
  // { path: "/gamerprofile", exact: true, component: <GamerProfile /> },
  // { path: "/gameprofile", exact: true, component: <GameProfile /> },
  // { path: "/teamlist", exact: true, component: <Teamlist /> },
  // { path: "/nftlist", exact: true, component: <NftList /> },
  // { path: "/userprofile", exact: true, component: <UserProfile /> },
  { path: "/privacy", exact: true, component: <Privacy /> },
  { path: "/terms", exact: true, component: <Terms /> },
  //
  // { path: "/home2", exact: true, component: PageHome },
  // { path: "/home-header-2", exact: true, component: PageHome },
  //
  // { path: "/games", component: LandingPage },
  // { path: "/teams", component: LandingPage },
  // { path: "/gamers", component: LandingPage },
  // { path: "/collection/:id", component: LandingPage },
  //
];

const Routess = () => {
  return (
    // <BrowserRouter  basename="/">

    <Routes>
      {pages.map(({ component, path }) => {
        return (
          <Route
            key={path}
            element={component}
            // component={component}
            // exact={!!exact}
            path={path}
          />
        );
      })}
      {/* <Route component={Page404} /> */}
    </Routes>
    // {/* <Footer /> */}
    // </BrowserRouter>
  );
};

export default Routess;
