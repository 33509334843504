import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import tg1 from "../../assets/image/trending/tg1.png";
import tg2 from "../../assets/image/trending/2.png";
import tg3 from "../../assets/image/trending/3.png";
import tg4 from "../../assets/image/trending/4.png";
import tg5 from "../../assets/image/trending/5.png";
import tg6 from "../../assets/image/trending/tg12.png";
import tg7 from "../../assets/image/trending/7.png";
import tg8 from "../../assets/image/trending/tg6.png";
import tg9 from "../../assets/image/trending/9.png";
import tg10 from "../../assets/image/trending/tg4.png";
import tg11 from "../../assets/image/trending/tg2.png";
import tg12 from "../../assets/image/trending/tg5.png";
import tg13 from "../../assets/image/trending/13.png";
import tg14 from "../../assets/image/trending/14.png";
import tg15 from "../../assets/image/trending/tg10.png";
import tg16 from "../../assets/image/trending/16.png";
import tg17 from "../../assets/image/trending/tg16.png";
import "./trendingGames.css";
import TrendingPolygon from "./TrendingPolygon";

const settings2 = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  // autoplay: true,
  responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
};

const trendingG = [
  { id: 1, imageGame: tg1 },
  { id: 2, imageGame: tg2 },
  { id: 3, imageGame: tg3 },
  { id: 4, imageGame: tg4 },
  { id: 5, imageGame: tg5 },
  { id: 6, imageGame: tg6 },
  { id: 7, imageGame: tg7 },
  { id: 8, imageGame: tg8 },
  { id: 9, imageGame: tg9 },
  { id: 10, imageGame: tg10 },
  { id: 11, imageGame: tg11 },
  { id: 12, imageGame: tg12 },
  { id: 13, imageGame: tg13 },
  { id: 14, imageGame: tg14 },
  { id: 15, imageGame: tg15 },
];

const TrendingGames = () => {
  return (
    <section className=" mt-28">
      <h2 className="text-center font-Orbitron md:text-[40px] text-2xl font-bold text-green uppercase md:mb-8 mb-4">trending games</h2>
      <h2 className="text-center font-poppins md:text-[18px] text-xs text-white uppercase">
        <span className="text-white">
          EXPLORE OUR TRENDING <span className="text-green"> games</span>
          </span>
      </h2>
      {/* <div className="flex md:justify-center items-center w-full overflow-auto">
      
        <div className="flex flex-col justify-center items-center gap-[10px] pt-[160px] ">
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg1} alt="images" />
            </div>
          </div>
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg2} alt="images" />
            </div>
          </div>
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg3} alt="images" />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-[10px] -ml-[32px] ">
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg4} alt="images" />
            </div>
          </div>
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg5} alt="images" />
            </div>
          </div>
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg6} alt="images" />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-[10px] -ml-[32px] ">
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg7} alt="images" />
            </div>
          </div>
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg8} alt="images" />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-[10px] -mx-[32px] ">
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg6} alt="images" />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-[10px] -mr-[32px] ">
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg10} alt="images" />
            </div>
          </div>
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg11} alt="images" />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-[10px] -mr-[32px] ">
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg12} alt="images" />
            </div>
          </div>
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg13} alt="images" />
            </div>
          </div>
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg14} alt="images" />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-[10px] pt-[160px] ">
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg15} alt="images" />
            </div>
          </div>
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg16} alt="images" />
            </div>
          </div>
          <div className="trending-dropShadow">
            <div className={` trending-polygon h-[150px] w-[170px] trendingP `}>
              <img className="w-full h-full object-cover" src={tg17} alt="images" />
            </div>
          </div>
        </div>
      </div> */}
      <div className=" mt-20">
        <TrendingPolygon/>
      </div>
      <div className=" mt-20 hidden">
      {/* <Slider {...settings2}> */}
        <div className=" relative">
          <div className="games-hex overflow-auto">
              <div className=" ml-6">
                <img src={tg1}/>
                <img src={tg2}/>
              </div>
              <div className=" flex items-center">
                <img src={tg3}/>
              </div>
              <div>
                <img src={tg4}/>
                <img src={tg5}/>
              </div>
              <div className=" flex items-center">
                <img src={tg6}/>
              </div>
              <div>
                <img src={tg7}/>
                <img src={tg8}/>
              </div>
              <div className=" flex items-center">
                <img src={tg9}/>
              </div>
              <div>
                <img src={tg10}/>
                <img src={tg11}/>
              </div>
              <div className=" flex items-center">
                <img src={tg12}/>
              </div>
              <div>
                <img src={tg13}/>
                <img src={tg14}/>
              </div>
              <div className=" flex items-center">
                <img src={tg15}/>
              </div>
              <div className=" mr-6">
                <img src={tg16}/>
                <img src={tg17}/>
              </div>
          </div>
          {/* <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
            <span className="swiper-pagination-bullet"></span>
            <span className="swiper-pagination-bullet"></span>
          </div> */}
        </div>
      {/* </Slider> */}
      {/* <Slider {...settings2}>
        <div className="mob-hex">
          <div className=" grid grid-cols-3">
            <div>
              <img src={tg1}/>
              <img src={tg1}/>
            </div>
            <div className=" col-span-1 flex items-center">
              <img src={tg1}/>
            </div>
            <div>
              <img src={tg1}/>
              <img src={tg1}/>
            </div>
          </div>
        </div>
        <div className="mob-hex">
          <div className=" grid grid-cols-3">
            <div className=" col-span-1 flex items-center">
              <img src={tg1}/>
            </div>
            <div>
              <img src={tg1}/>
              <img src={tg1}/>
            </div>
            <div className=" col-span-1 flex items-center">
              <img src={tg1}/>
            </div>
          </div>
        </div>
        <div className="mob-hex">
          <div className=" grid grid-cols-3">
            <div>
              <img src={tg1}/>
              <img src={tg1}/>
            </div>
            <div className=" col-span-1 flex items-center">
              <img src={tg1}/>
            </div>
            <div>
              <img src={tg1}/>
              <img src={tg1}/>
            </div>
          </div>
        </div>
      </Slider> */}
      </div>
    </section>
  );
};

export default TrendingGames;
