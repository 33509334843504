import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import polygon from "../../assets/image/partners/polygon.png";
import lab from "../../assets/image/partners/heftylab.png";
import hindus from "../../assets/image/partners/Hindustan.png";
import hog from "../../assets/image/partners/housegaming1.png";
import hunga from "../../assets/image/partners/Hungama.png";
import edao from "../../assets/image/partners/edao.png";
import fin from "../../assets/image/partners/fin.png";

const OurPartners = () => {
  const settings2 = {
    dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "70px",
    autoplay: true,
  };

  return (
    <section className=" mt-32">
      <h2 className="text-center font-Orbitron md:text-[40px] text-2xl font-bold text-green uppercase md:mb-8 mb-4">our partners</h2>
      <h2 className="text-center font-poppins md:text-[18px] text-xs text-white uppercase ">
        meet our <span className="text-green">partners</span>
      </h2>
      <div className=" md:block hidden">
        <div className="flex px-[30px] justify-center items-center lg:gap-[150px] gap-[50px] mt-11 overflow-auto">
          <img className="w-[200px] object-contain" src={polygon} alt="partner" />
          <img className="w-[200px] object-contain" src={hog} alt="partner" />
          <img className="w-[200px] object-contain" src={hunga} alt="partner" />
          
        </div>
        <div className="flex px-[30px] lg:gap-[150px] gap-[50px] justify-center items-center overflow-auto mt-12">
          {/* <img className="w-[200px] object-contain" src={lab} alt="partner" /> */}
          <img className="w-[200px] object-contain" src={fin} alt="partner" />
          
          <img className="w-[200px] object-contain" src={hindus} alt="partner" />
          <img className="w-[200px] px-2 object-contain" src={edao} alt="partner" />
          
        </div>
      </div>

      <div className=" md:hidden block partner-slid">
        <Slider {...settings2}>
                <img className="w-full px-5 py-9" src={hog}/>
                <img className="w-full px-5 py-9" src={hunga}/>
                <img className="w-full px-5 py-9" src={fin}/>
                <img className="w-full px-5 py-9" src={hindus}/>
                <img className="w-full px-5 py-9" src={edao}/>
                {/* <img className="w-full px-5 py-9" src={lab}/> */}
                <img className="w-full px-5 py-9" src={polygon}/>
        </Slider>
      </div>
    </section>
  );
};

export default OurPartners;
