import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useRef } from "react";

import leftimg from "../../assets/image/journey-left.png"
import rightimg from "../../assets/image/journey-right.png"

gsap.registerPlugin(ScrollTrigger);


const OurJourney = () => {
  const scrollsvg = useRef<any>()
  const scrollsvgmob = useRef<any>()
  const pathsvg = useRef<any>()
  const pathsvgmob = useRef<any>()


  const mainbox1 = useRef<any>()
  const box1 = useRef<any>()
  const box1text = useRef<any>()

  const mainbox2 = useRef<any>()
  const box2 = useRef<any>()
  const box2text = useRef<any>()

  const mainbox3 = useRef<any>()
  const box3 = useRef<any>()
  const box3text = useRef<any>()

  const mainbox4 = useRef<any>()
  const box4 = useRef<any>()
  const box4text = useRef<any>()

  const mainbox5 = useRef<any>()
  const box5 = useRef<any>()
  const box5text = useRef<any>()

  const mainbox6 = useRef<any>()
  const box6 = useRef<any>()
  const box6text = useRef<any>()


  useEffect(() => {
   
    const time1= gsap.timeline({
      scrollTrigger:{
        trigger:scrollsvg.current,
        start:"top 50%",
        end:"bottom 50%",
        // markers:true,
        scrub: true,
      }

    });
    time1
        .fromTo(pathsvg.current, { strokeDashoffset: 778},{strokeDashoffset:0});
  },[])

  useEffect(() => {
   
    const time2= gsap.timeline({
      scrollTrigger:{
        trigger:scrollsvgmob.current,
        start:"top 50%",
        end:"bottom 50%",
        // markers:true,
        scrub: true,
      }

    });
    time2
      .fromTo(pathsvgmob.current, { strokeDashoffset: 1015},{strokeDashoffset:2});
  },[])

  useEffect(() => {
   
    const time2= gsap.timeline({
      scrollTrigger:{
        trigger:mainbox1.current,
        start:"top 50%",
        end:"top 50%",
        // markers:true,
        scrub: true,
      }

    });
    time2.fromTo(box1.current, { fill: "transparent"},{fill: "#1DF66A"});
    time2.fromTo(box1text.current, { color: "#1DF66A"},{color: "#000"});
  },[])

  useEffect(() => {
    if (window.innerWidth > 768) {
      const time2= gsap.timeline({
        scrollTrigger:{
          trigger:mainbox2.current,
          start:"-43px 50%",
          end:"-43px 50%",
          // markers:true,
          scrub: true,
        }
  
      });
      time2.fromTo(box2.current, { fill: "transparent"},{fill: "#1DF66A"});
      time2.fromTo(box2text.current, { color: "#1DF66A"},{color: "#000"});
    }
    else{
      const time2= gsap.timeline({
        scrollTrigger:{
          trigger:mainbox2.current,
          start:"-200% 50%",
          end:"-200% 50%",
          // markers:true,
          scrub: true,
        }
  
      });
      time2.fromTo(box2.current, { fill: "transparent"},{fill: "#1DF66A"});
      time2.fromTo(box2text.current, { color: "#1DF66A"},{color: "#000"});
    }
    
  },[])

  useEffect(() => {
    if (window.innerWidth > 768) {
      const time2= gsap.timeline({
        scrollTrigger:{
          trigger:mainbox3.current,
          start:"-190% 50%",
          end:"-190% 50%",
          // markers:true,
          scrub: true,
        }
  
      });
      time2.fromTo(box3.current, { fill: "transparent"},{fill: "#1DF66A"});
      time2.fromTo(box3text.current, { color: "#1DF66A"},{color: "#000"});
    }
    else{
      const time2= gsap.timeline({
        scrollTrigger:{
          trigger:mainbox3.current,
          start:"-350% 50%",
          end:"-350% 50%",
          // markers:true,
          scrub: true,
        }
  
      });
      time2.fromTo(box3.current, { fill: "transparent"},{fill: "#1DF66A"});
      time2.fromTo(box3text.current, { color: "#1DF66A"},{color: "#000"});
    }
    
  },[])

  useEffect(() => {
    if (window.innerWidth > 768) {
      const time2= gsap.timeline({
        scrollTrigger:{
          trigger:mainbox4.current,
          start:"-230% 50%",
          end:"-230% 50%",
          // markers:true,
          scrub: true,
        }
  
      });
      time2.fromTo(box4.current, { fill: "transparent"},{fill: "#1DF66A"});
      time2.fromTo(box4text.current, { color: "#1DF66A"},{color: "#000"});
    }
    else{
      const time2= gsap.timeline({
        scrollTrigger:{
          trigger:mainbox4.current,
          start:"-470% 50%",
          end:"-470% 50%",
          // markers:true,
          scrub: true,
        }
  
      });
      time2.fromTo(box4.current, { fill: "transparent"},{fill: "#1DF66A"});
      time2.fromTo(box4text.current, { color: "#1DF66A"},{color: "#000"});
    }
    
  },[])

  useEffect(() => {
    if (window.innerWidth > 768) {
      const time2= gsap.timeline({
        scrollTrigger:{
          trigger:mainbox5.current,
          start:"-320% 50%",
          end:"-320% 50%",
          // markers:true,
          scrub: true,
        }
  
      });
      time2.fromTo(box5.current, { fill: "transparent"},{fill: "#1DF66A"});
      time2.fromTo(box5text.current, { color: "#1DF66A"},{color: "#000"});
    }
    else{
      const time2= gsap.timeline({
        scrollTrigger:{
          trigger:mainbox5.current,
          start:"-570% 50%",
          end:"-570% 50%",
          // markers:true,
          scrub: true,
        }
  
      });
      time2.fromTo(box5.current, { fill: "transparent"},{fill: "#1DF66A"});
      time2.fromTo(box5text.current, { color: "#1DF66A"},{color: "#000"});
    }
    
  },[])

  useEffect(() => {
    if (window.innerWidth > 768) {
      const time2= gsap.timeline({
        scrollTrigger:{
          trigger:mainbox6.current,
          start:"-280% 50%",
          end:"-280% 50%",
          // markers:true,
          scrub: true,
        }
  
      });
      time2.fromTo(box6.current, { fill: "transparent"},{fill: "#1DF66A"});
      time2.fromTo(box6text.current, { color: "#1DF66A"},{color: "#000"});
    }
    else{
      const time2= gsap.timeline({
        scrollTrigger:{
          trigger:mainbox6.current,
          start:"-580% 50%",
          end:"-580% 50%",
          // markers:true,
          scrub: true,
        }
  
      });
      time2.fromTo(box6.current, { fill: "transparent"},{fill: "#1DF66A"});
      time2.fromTo(box6text.current, { color: "#1DF66A"},{color: "#000"});
    }
    
  },[])


  return (
    <section className=" mt-16">
      <h2 className="text-center font-Orbitron md:text-[40px] text-2xl font-bold text-green uppercase md:mb-8 mb-4">our journey</h2>
      <h2 className="text-center font-poppins md:text-[18px] text-xs text-white uppercase">
        road to <span className="text-green">web 3.0</span>
      </h2>
      <div className="mt-12">
        <div className="grid md:grid-cols-2 relative md:gap-x-14 gap-x-5 w-full md:pl-0 pl-10 md:gap-y-14 gap-y-5">
          <div className="absolute h-full md:left-0 left-4 right-0 m-auto z-0">
              <svg ref={scrollsvg} className="m-auto md:block hidden w-[6%]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 646" fill="none">
              <path d="M12.5 3L73 94L2.5 201L82.5 317.5L9.31614 419.5L89.3161 535.5L14 644.5" stroke="#7A7A7A" stroke-width="4"/>
              <path style={{strokeDasharray:778,strokeDashoffset:778}} ref={pathsvg} d="M12.5 3L73 94L2.5 201L82.5 317.5L9.31614 419.5L89.3161 535.5L14 644.5" stroke="#1df66a" stroke-width="4"/>
              </svg>

            <svg ref={scrollsvgmob} className="md:hidden block h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 868" fill="none">
            <path d="M39.1975 1L2.28395 66.3141L48 140.03L2 208.867L48 287.732L2 359.821L48 433.943L5.40741 504L48 577L5.40741 648.5L48 726.5L5.40741 795L48 866.5" stroke="#7A7A7A" stroke-opacity="1" stroke-width="2"/>
            <path style={{strokeDasharray:1015,strokeDashoffset:1015}} ref={pathsvgmob}  d="M39.1975 1L2.28395 66.3141L48 140.03L2 208.867L48 287.732L2 359.821L48 433.943L5.40741 504L48 577L5.40741 648.5L48 726.5L5.40741 795L48 866.5" stroke="#1DF66A" stroke-opacity="1" stroke-width="2"/>
            </svg>
          </div>

            <div className="journey-back h-fit z-1 relative left">
              <img src={leftimg} className="w-full md:block hidden"/>
              <div className="md:w-[10%] w-[11%] absolute md:right-[6.8%] md:left-auto left-[1.8rem] md:top-[0] top-[0.2rem]">
                <div ref={mainbox1} className="relative">
                  <svg ref={box1} style={{fill:"transparent"}} className="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 56" fill="none">
                    <path d="M48.4243 0.5L17.0016 0.5L1.29032 27.7128L17.0016 54.9256L48.4243 54.9256L64.1356 27.7128L48.4243 0.5Z" stroke="#1DF66A"/>
                  </svg>
                  <span ref={box1text} className="
                    text-[#1DF66A] 
                    font-Orbitron 
                    font-semibold 
                    xl:text-2xl
                    lg:text-xl
                    md:text-lg
                    text-sm
                    absolute
                    left-0
                    right-0
                    bottom-0
                    top-0
                    flex
                    items-center
                    justify-center">Q1</span>
                </div>
              </div>
              <div className="md:w-[88%] w-full md:absolute md:py-5 right-0 top-0">
                <p className=" text-green font-Orbitron font-semibold xl:text-2xl lg:text-xl text-xs mb-4">Gamegods Awakens</p>
                <ul className=" text-white opacity-60 font-poppins xl:text-sm lg:text-[12px] text-[10px] font-normal md:min-h-max min-h-[60px]">
                  <li>-Gamegods Beta access for 10 k Users</li>
                  <li>-Free Mint for the first adopters</li>
                </ul>
              </div>
            </div>
            <div className="journey-back h-fit z-1 relative right-0 right md:top-[50%]">
            <img src={rightimg} className="w-full md:block hidden"/>
            <div className="md:w-[10%] w-[11%] absolute md:left-[6.8%] left-[1.8rem] md:top-[0] top-[0.2rem]">
                <div ref={mainbox2} className="relative">
                  <svg ref={box2} style={{fill:"transparent"}} className="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 56" fill="none">
                    <path d="M48.4243 0.5L17.0016 0.5L1.29032 27.7128L17.0016 54.9256L48.4243 54.9256L64.1356 27.7128L48.4243 0.5Z" stroke="#1DF66A"/>
                  </svg>
                  <span ref={box2text} className="
                    text-[#1DF66A] 
                    font-Orbitron 
                    font-semibold 
                    xl:text-2xl
                    lg:text-xl
                    md:text-lg
                    text-sm
                    absolute
                    left-0
                    right-0
                    bottom-0
                    top-0
                    flex
                    items-center
                    justify-center">Q2</span>
                </div>
              </div>
              <div className="md:w-[81%] w-full md:absolute md:py-5 right-0 top-0">
                <p className=" text-green font-Orbitron font-semibold xl:text-2xl lg:text-xl text-xs mb-4">HeftyGames Invasion</p>
                <ul className=" text-white opacity-60 font-poppins xl:text-sm lg:text-[12px] text-[10px] font-normal md:min-h-max min-h-[60px]">
                  <li>-The Gamegods collection is revealed</li>
                  <li>-Player cards exclusives</li>
                  <li>-Custom Tournament creation feature</li>
                </ul>
              </div>
            </div>
            <div className="journey-back h-fit z-1 relative left md:top-[-12%]">
              <img src={leftimg} className="w-full md:block hidden"/>
            <div className="md:w-[10%] w-[11%] absolute md:right-[6.8%] md:left-auto left-[1.8rem] md:top-[0] top-[0.2rem]">
                <div ref={mainbox3} className="relative">
                  <svg ref={box3} style={{fill:"transparent"}} className="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 56" fill="none">
                    <path d="M48.4243 0.5L17.0016 0.5L1.29032 27.7128L17.0016 54.9256L48.4243 54.9256L64.1356 27.7128L48.4243 0.5Z" stroke="#1DF66A"/>
                  </svg>
                  <span ref={box3text} className="
                    text-[#1DF66A] 
                    font-Orbitron 
                    font-semibold 
                    xl:text-2xl
                    lg:text-xl
                    md:text-lg
                    text-sm
                    absolute
                    left-0
                    right-0
                    bottom-0
                    top-0
                    flex
                    items-center
                    justify-center">Q3</span>
                </div>
              </div>
              <div className="md:w-[88%] w-full md:absolute md:py-5 right-0 top-0">
                <p className=" text-green font-Orbitron font-semibold xl:text-2xl lg:text-xl text-xs mb-4">Wraith of the Gamegods</p>
                <ul className=" text-white opacity-60 font-poppins xl:text-sm lg:text-[12px] text-[10px] font-normal md:min-h-max min-h-[60px]">
                  <li>-Reveal of legendary tier </li>
                  <li>-Gamegods Partners </li>
                </ul>
              </div>
            </div>
            <div className="journey-back h-fit z-1 relative right-0 right md:top-[40%]">
            <img src={rightimg} className="w-full md:block hidden"/>
            <div className="md:w-[10%] w-[11%] absolute md:left-[6.8%] left-[1.8rem] md:top-[0] top-[0.2rem]">
                <div ref={mainbox4} className="relative">
                  <svg ref={box4} style={{fill:"transparent"}} className="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 56" fill="none">
                    <path d="M48.4243 0.5L17.0016 0.5L1.29032 27.7128L17.0016 54.9256L48.4243 54.9256L64.1356 27.7128L48.4243 0.5Z" stroke="#1DF66A"/>
                  </svg>
                  <span ref={box4text} className="
                    text-[#1DF66A] 
                    font-Orbitron 
                    font-semibold 
                    xl:text-2xl
                    lg:text-xl
                    md:text-lg
                    text-sm
                    absolute
                    left-0
                    right-0
                    bottom-0
                    top-0
                    flex
                    items-center
                    justify-center">Q4</span>
                </div>
              </div>
              <div className="md:w-[81%] w-full md:absolute md:py-5 right-0 top-0">
                <p className=" text-green font-Orbitron font-semibold xl:text-2xl lg:text-xl text-xs mb-4">Hefty Games Utilities </p>
                <ul className=" text-white opacity-60 font-poppins xl:text-sm lg:text-[12px] text-[10px] font-normal md:min-h-max min-h-[60px]">
                  <li>-Each NFT is loaded and packed with over 30+ utilities</li>
                  <li>-Click here for more info on what utilities holders will receive</li>
                </ul>
              </div>
            </div>
            <div className="journey-back h-fit z-1 relative left md:top-[-20%]">
              <img src={leftimg} className="w-full md:block hidden"/>
            <div className="md:w-[10%] w-[11%] absolute md:right-[6.8%] md:left-auto left-[1.8rem] md:top-[0] top-[0.2rem]">
                <div ref={mainbox5} className="relative">
                  <svg ref={box5} style={{fill:"transparent"}} className="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 56" fill="none">
                    <path d="M48.4243 0.5L17.0016 0.5L1.29032 27.7128L17.0016 54.9256L48.4243 54.9256L64.1356 27.7128L48.4243 0.5Z" stroke="#1DF66A"/>
                  </svg>
                  <span ref={box5text} className="
                    text-[#1DF66A] 
                    font-Orbitron 
                    font-semibold 
                    xl:text-2xl
                    lg:text-xl
                    md:text-lg
                    text-sm
                    absolute
                    left-0
                    right-0
                    bottom-0
                    top-0
                    flex
                    items-center
                    justify-center">Q5</span>
                </div>
              </div>
              <div className="md:w-[88%] w-full md:absolute md:py-5 right-0 top-0">
                <p className=" text-green font-Orbitron font-semibold xl:text-2xl lg:text-xl text-xs mb-4">Hefty Games - partners </p>
                <ul className=" text-white opacity-60 font-poppins xl:text-sm lg:text-[12px] text-[10px] font-normal md:min-h-max min-h-[60px]">
                  <li>-Access to our exclusive line up of game partners </li>
                  <li>-Click here to become our game partner </li>
                </ul>
              </div>
            </div>
            <div className="journey-back h-fit z-1 relative right-0 right md:top-[30%]">
            <img src={rightimg} className="w-full md:block hidden"/>
            <div className="md:w-[10%] w-[11%] absolute md:left-[6.8%] left-[1.8rem] md:top-[0] top-[0.2rem]">
                <div ref={mainbox6} className="relative">
                  <svg ref={box6} style={{fill:"transparent"}} className="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 56" fill="none">
                    <path d="M48.4243 0.5L17.0016 0.5L1.29032 27.7128L17.0016 54.9256L48.4243 54.9256L64.1356 27.7128L48.4243 0.5Z" stroke="#1DF66A"/>
                  </svg>
                  <span ref={box6text} className="
                    text-[#1DF66A] 
                    font-Orbitron 
                    font-semibold 
                    xl:text-2xl
                    lg:text-xl
                    md:text-lg
                    text-sm
                    absolute
                    left-0
                    right-0
                    bottom-0
                    top-0
                    flex
                    items-center
                    justify-center">Q6</span>
                </div>
              </div>
              <div className="md:w-[81%] w-full md:absolute md:py-5 right-0 top-0">
                <p className=" text-green font-Orbitron font-semibold xl:text-2xl lg:text-xl text-xs mb-4">Hefty Games Metaverse</p>
                <ul className=" text-white opacity-60 font-poppins xl:text-sm lg:text-[12px] text-[10px] font-normal md:min-h-max min-h-[60px]">
                  <li className="md:pr-11">-The ultimate gamers metaverse is coming soon! visit the relics of ancient Athens and face the gods</li>
                </ul>
              </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default OurJourney;
