const Terms = () => {
    return(
        <section className=" overflow-hidden pb-10 pt-20 md:pt-40 relative">
            <div className="width-1920">
                <div className=" text-center w-full flex justify-center items-center flex-col gap-2 sm:gap-5 text-white">
                    <p className=" w-fit absolute opacity-5 z-0 top-0 left-[50%] right-[50%] translate-x-[-50%] 2xl:text-[200px] md:text-[160px] text-[100px] font-Orbitron whitespace-nowrap">Terms and condition</p>
                    <h1 className="relative z-1 font-Orbitron font-semibold 2xl:text-7xl lg:text-6xl md:text-5xl text-2xl">Terms <span className=" text-green">and condition</span></h1>
                </div>
                <div className=" mt-20 text-white">
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60">
                    THESE TERMS OF USE (“AGREEMENT”) IS AN ELECTRONIC RECORD IN THE FORM OF AN ELECTRONIC CONTRACT FORMED UNDER THE INFORMATION TECHNOLOGY ACT, 2000 AND THE RULES MADE THEREUNDER AND THE AMENDED PROVISIONS PERTAINING TO ELECTRONIC DOCUMENTS / RECORDS IN VARIOUS STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THIS AGREEMENT DOES NOT REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.
                    </p>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60 mt-4">
                    THIS AGREEMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF RULE 3(1) OF THE INFORMATION TECHNOLOGY (INTERMEDIARIES GUIDELINES) RULES, 2011 THAT REQUIRE PUBLISHING THE RULES AND REGULATIONS, PRIVACY POLICY AND TERMS OF USE FOR ACCESS OR USAGE OF A WEBSITE/APPLICATION/DIGITAL PLATFORM.
                    </p>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60 mt-4">
                    PLEASE READ THIS AGREEMENT CAREFULLY AS IT CONTAINS IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS; AND INCLUDES VARIOUS LIMITATIONS AND EXCLUSIONS, A CLAUSE THAT GOVERNS THE JURISDICTION AND VENUE OF DISPUTES, AND OBLIGATIONS TO COMPLY WITH APPLICABLE LAWS AND REGULATIONS.
                    </p>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60 mt-4">
                    This Agreement sets forth the legally binding terms and conditions for your access to and use of the mobile application ‘Game Gods’ ( “App”) and any other associated products or services, websites, games and/or software, including but not limited to the website under the domain name ‘gamegods.com’ (the “Services”) being offered by House of Gaming Private Limited, a private limited company registered under the Companies Act, 2013 bearing CIN U74996MH2018PTC315072 and registered address at 801, 8th  Floor, Fortune Enclave, 14th Road, Khar West, Bandra Suburban, Mumbai 400052, Maharashtra, India (“HOG”, ”Company” “us”, “our”, and “we”). The “User”, “you”, “your” shall refer to any natural person or entity and its authorized users that subscribe or use our Services.
                    </p>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60 mt-4">
                    By accessing or using our App, you are accepting this Agreement (on behalf of yourself or the entity that you represent), and you represent and warrant that you have the right, authority, and capacity to enter into this Agreement (on behalf of yourself or the entity that you represent). You may not access or use the App or accept this Agreement if you are not at least 18 years old. If at all a minor uses the services provided by us, we assume that such use by a minor is under the supervision of their guardian(s).  If you do not agree with all of the provisions of this Agreement, do not access and/or use the App. Please read this Agreement along with the Privacy Policy which is available on [•insert URL] and is incorporated herein by reference, before you use the Services provided by us. By accessing and using the Services, you affirm that you have read, understood, and will comply with the terms and conditions mentioned herein. 
                    </p>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60 mt-4">
                    We may, in our sole and absolute discretion and without any specific notice to you, update and change any part or all of this Agreement, including but not limited to the fees and charges associated with the use of or access to our Services. If we update or change this Agreement, then the updated version will be posted at [•insert URL] or on the relevant products’/services’ page. When we change/modify this Agreement, we will specify the ‘Last Modified’ date and the updated agreement will become effective and binding from such date. You are required to review this Agreement periodically/from time to time. Unless explicitly stated otherwise, any new features or products that change, augment or enhance our current services and/or products shall be subject to the terms of this Agreement.
                    </p>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60 mt-4">
                    As part of our Services, you as a user may from time to time receive updates/upgrades to our products and/or services which may be automatically downloaded and installed to the users’ device/systems or reflected on associated websites. These updates may include bug fixes, feature enhancements or improvements, or entirely new versions of our Services. You agree that we may automatically deliver such updates to you as part of our Services and you shall receive and install them as required for continuous services/use.
                    </p>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60 mt-4">
                    You also agree and acknowledge that your ability to use the Services may be detrimentally impacted by regulatory action or changes in any applicable regulations. You further agree that we may disclose your personal and account information if we believe that it is reasonably necessary to comply with any law, regulation, legal process, or governmental request as may be required by the relevant authority.
                    </p>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60 mt-4">
                    Game Gods is merely a gaming and technology platform, and you are solely and entirely responsible for the legal, regulatory and tax compliance of all transactions carried out by you using the Services.
                    </p>
                </div>
                <div className=" mt-16 text-white">
                    <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">WHY SHOULD YOU READ THE TERMS OF USE </h4>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60">You should read the terms and conditions set out under this Agreement as they contain our legal commitments to you and a number of DOs and DON'Ts which you need to be aware of when you use our Services. Please read these terms and conditions carefully in order to understand them. By accessing our Website and using the Services provided by us therein, you agree to accept and be legally bound by these terms and conditions. If you do not agree with this Agreement, you should not access or use the Services we provide on our App.
                </p>
                <div className=" mt-16 text-white">
                    <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">ACCOUNTS </h4>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60">
                        <ul className=" list-disc list-inside">
                        <li>Account Creation. In order to use certain features of the App, you must register your account (“Account”) and provide certain information about yourself as prompted by the account registration form. You must create a unique username along with a high security password after submitting certain personal information. You are required to provide certain documents for verification of age, residence, and authenticity of the information as part of the Know Your Customer (“KYC”) process. You represent and warrant that: (a) all required registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information; (c) your use of the Services is and shall be in accordance with applicable law. You may delete your Account at any time, for any reason, by following the instructions on the App and the website respectively. In case of any discrepancy or malpractices conducted by you on the App, or any other reason as we may deem fit, we shall suspend or terminate your Account in accordance with Section 12. </li>
                        <li>Identity Verification. You agree to provide us with the information we request for the purposes of identity verification and the detection of money laundering, terrorist financing, fraud, or any other financial crime and permit us to keep a record of such information. You will need to complete certain verification procedures before you are permitted to use the Services and your access to the Services may be altered as a result of information collected on an ongoing basis. The information we request may include certain personal information, including, but not limited to, your name, address, telephone number, e-mail address, date of birth, taxpayer identification number, government identification number, and information regarding your bank account (such as the name of the bank, the account type, routing number, and account number). In providing us with this or any other information that may be required, you confirm that the information is accurate and authentic. You agree to update this information and keep it current at all times. You authorize us to make the inquiries, whether directly or through third parties, that we consider appropriate, in our sole discretion, to verify your identity or protect you and/or us against fraud or other financial crimes, and to take action we reasonably deem necessary based on the results of such inquiries.</li>
                        <li>Certain Restrictions. By using the Services, you represent and warrant that: (i) neither you nor any of your directors, officers, employees, agents, affiliates or representatives is an individual or an entity that is, or is owned or controlled by an individual or entity that is (a) currently the subject of any Sanctions, or (b) located, organized or resident in a Designated Jurisdiction; (ii) you have complied in all material respects with all applicable laws relating to Sanctions, anti-terrorism, anti-corruption and anti-money laundering; and (iii) you have instituted and maintained policies and procedures designed to promote and achieve compliance with such laws. For the purpose of this Agreement, “Designated Jurisdiction” means any country or territory to the extent that such country or territory itself is the subject of any Sanction; “Sanction(s)” means any international economic sanction administered or enforced by the United States Government (including OFAC), the United Nations Security Council, the European Union or other relevant sanctions authority.</li>
                        <li>Account Responsibilities. You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account including all consequences under applicable laws. You agree to immediately notify us of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. We cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.</li>
                        <li>Compliance. You are solely responsible for ensuring that your use of the Services is in compliance with all laws, rules, and regulations applicable to you and the right to access the Services is automatically revoked where use of the Services is prohibited or to the extent that the offering, sale or provision of the Services conflicts with any law, rule or regulation applicable to you.</li>
                        <li>Available Only Where Permitted by Law. The Services are only available in jurisdictions where they may be legally offered for sale. By accessing or using the Services, you represent and warrant that you are not a person who is barred from accessing the Services as provided on our App. </li>
                        </ul>
                    </p>
                </div>
                <div className=" mt-16 text-white">
                    <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">ACCESS TO THE SERVICES </h4>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60">
                        <ul className=" list-disc list-inside">
                            <li>License. Subject to this Agreement, we grant you a non-transferable, non-exclusive, revocable, limited license to use and access the Services solely for your own personal use, in accordance with the account type you are currently subscribing to.</li>
                            <li>Modification. We reserve the right, at any time, to modify, suspend, or discontinue the Services (in whole or in part) with or without notice to you. You agree that we will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Services or any part thereof.</li>
                            <li>No Support or Maintenance. You acknowledge and agree that we will have no obligation to provide you with any support or maintenance in connection with the Services.</li>
                            <li>Ownership. You acknowledge that all the intellectual property rights in the Services offered on App and the App itself, including but not limited to the copyrights, patents, trademarks, and trade secrets, in the Services and its content are owned by HOG or HOG’s subsidiaries, affiliates or authorized third-parties. Neither this Agreement (nor your access to the Services) transfers to you or any unauthorized third party any rights, title or interest in or to such intellectual property rights.  We and our subsidiaries, affiliates or authorized third-parties reserve all rights not granted in this Agreement. There are no implied licenses granted under this Agreement.</li>
                            <li>Attacks On the App. We cannot prevent or mitigate attacks on the App or blockchain networks and have no obligation to engage in activity in relation to such attacks. In the event of an attack, we reserve the right to take commercially reasonable actions, including, but not limited to, if we confirm that a Service is compromised or under attack, immediately halting trading, deposits, and withdrawals for such Service. Resolutions concerning deposits, withdrawals and balances for User that has had its network attacked will be determined on a case-by-case basis by us in our sole discretion. We have made no representation and do not warrant the safety on the Services. and you assume all liability for any losses incurred by you from the use of the Services.</li>
                        </ul>
                    </p>
                </div>
                <div className=" mt-16 text-white">
                    <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">PROHIBITED ACTIVITIES </h4>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60">
                        <ul className=" list-disc list-inside">
                           <li>Prohibited Activities. In connection with your use of the Services, you hereby agree that you will not:</li>
                           <ul className=" list-disc list-inside">
                            <li>violate (or assist any other party in violating) any applicable law, statute, ordinance, or regulation;</li>
                            <li>intentionally try to defraud (or assist in the defrauding of) HOG or any other User(s);</li>
                            <li>provide false, inaccurate, or misleading information;</li>
                            <li>trade taking advantage of any technical glitch, malfunction, failure, delay, default, or security breach;</li>
                            <li>take any action that interferes with, intercepts, or expropriates any system, data, or information;</li>
                            <li>partake in any transaction involving the proceeds of illegal activity;</li>
                            <li>attempt to gain unauthorized access to other Accounts, the Services, or any related networks or systems;</li>
                            <li>use the Services on behalf of any third party or otherwise act as an intermediary between HOG and any third parties;</li>
                            <li>use the Services to engage in conduct that is detrimental to HOG or to any other User or any other third party;</li>
                            <li>collect any user information from other Users, including, without limitation, email addresses, etc.;</li>
                            <li>defame, harass, or violate the privacy or intellectual property rights of HOG and its subsidiaries, affiliates or authorized third-parties or any other User; or</li>
                            <li>upload, display or transmit any messages, photos, videos, or other media that contain illegal goods, pornographic, violent, obscene, or copyrighted images or materials for use as an avatar, in connection with a payment or payment request, or otherwise.</li>
                            <li>In addition, you agree not to: (i) upload, transmit, or distribute to or through the Services any computer viruses, worms, or any software intended to damage or   alter a computer system or data; (ii) interfere with, disrupt, or create an undue burden on servers or networks connected to the Services, or violate the regulations, policies or procedures of such networks; (iii) attempt to reverse engineer, de-compile, disable, interfere with, disassemble, copy, or disrupt the integrity or the performance of the Services, any third-party use of the Services, or any third-party data contained therein (except to the extent such restrictions are prohibited by applicable law; or (iv) access the Services in order to build a competitive product or service or copy any ideas, features, functions, or graphics of the Services.</li>
                           </ul>
                           <li>Enforcement. We reserve the right (but have no obligation) to investigate and/or take appropriate action against you in our sole discretion if you engage in any prohibited activities as mentioned under this Section 4 or violate any other provision of this Agreement or otherwise create liability for us or any other person. Such action may include, in our sole and absolute discretion, terminating your Account in accordance with Section 12, reporting you to law enforcement authorities without providing any notice of you about any such report and confiscating any balance remaining in an Account which has been terminated.</li>
                        </ul>
                    </p>
                </div>
                <div className=" mt-16 text-white">
                    <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">THIRD-PARTY PRODUCTS AND SERVICES; OTHER USERS </h4>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60">
                        <ul className=" list-disc list-inside">
                            <li>Third-Party Services. You acknowledge that the Service will enable or assist you to access, interact with, and/or purchase services from several supported platforms and other third parties via third-party websites or applications (collectively referred to as “Third-Party Services”). Such Third-Party Services are not under the control of HOG, HOG does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Services and is not responsible for any Third-Party Services. You use all Third-Party Services at your own risk and should apply a suitable level of caution and discretion in doing so. Any use of Third-Party Services is governed solely by the terms and conditions of such Third-Party Services and any contract entered into, or any transaction completed via any Third-Party Services, is between you and the relevant third party, and not with HOG. You shall comply in all respects with all applicable terms of the Third-Party Services that you access or subscribe to in connection with the Services. If at any time any Third-Party Services cease to make their programs available to us on reasonable terms, we may cease to provide such features to you without entitling you to refund, credit, or other compensation.</li>
                            <li>Other Users and Third-Parties. We do not guarantee the identity of any User or other third parties or ensure that a buyer or seller is authorized to complete a transaction or will complete a transaction. Your interactions with other Users or third parties are solely between you and such Users and third-parties. You agree that we will not be responsible for any loss or damage incurred as the result of any such interactions/ transactions. If there is a dispute between you and any User or third parties, we are under no obligation to become involved.</li>
                            <li>Release. You hereby release and forever discharge us (and our officers, employees, agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Services (including any interactions with, or act or omission of, other Users or any Third- Party Services and products).</li>
                        </ul>
                    </p>
                </div>
                <div className=" mt-16 text-white">
                    <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">DEPOSITS, WINNINGS AND WITHDRAWALS.  </h4>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60">
                        <ul className=" list-disc list-inside">
                            <li>Deposits includes the monies that you shall deposit into your account through which you will be able to participate in the Contests (defined below). The deposit feature of the App shall be available at all times and can be used by the Users through net banking, mobile banking, and unified payments interface (“UPI”) payment winnings refers to the amount that you win on the App. You shall solely earn rewards based on the skills being applied and a mere luck/ chance shall not be taken as a factor while distributing the winnings. The said winnings will be credited into your Account upon the completion of the Contests. The deposits and the winnings made by you which have been credited to your Account can be withdrawn by you by making a withdrawal request subject to the fulfillment and approval of the KYC process. Once the withdrawal request is approved by HOG, the earnings reflecting in your Account on the App will be transferred to your bank account, after the deduction of surcharge and other taxes, as may be applicable, which the User submits to HOG in compliance with the applicable laws.  We do not process withdrawal requests on bank holidays.</li>
                        </ul>
                    </p>
                </div>
                <div className=" mt-16 text-white">
                    <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">TRADE CORRECTIONS.  </h4>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60">
                    You acknowledge and agree that we may correct, reverse, or cancel, in its sole discretion, any order, trade, transfer, or other transaction or activity with respect to which we have discovered that there was an error or abnormality, whether such error or abnormality was caused by you, us or a third party or due to technical issues, system issues, software failure or otherwise. We provide no guarantee or warranty that any such attempt will be successful and will have no responsibility or liability for such error(s) or correction of such error(s).
                    </p>
                </div>
                <div className=" mt-16 text-white">
                    <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">OUR PROPRIETARY RIGHTS</h4>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60">
                        <ul className=" list-disc list-inside">
                            <li>This is an agreement for access to our Services, and save and except as provided herein, you are not granted any right, title, interest or license to any software or other intellectual property rights under this Agreement. Our Services are based upon our proprietary technology, intellectual property and confidential information and includes our content. Our Services are protected by applicable intellectual property and other laws, including patent, trademark, and copyright laws. Our Services, including all intellectual property rights therein, belongs to us or our licensors and is our or our licensors’ property. We own or are deemed to own and retain all rights including, without limitations, copyrights in our content. Our content shall not in any manner be copied, reproduced, modified, published, displayed, uploaded, posted, transmitted, performed, or distributed in any way by you, and you agree not to modify, rent, lease, time-share, loan, sell, distribute, transmit, broadcast, or create derivative works based on our content or our products and/or Services, in whole or in part, by any means, except as expressly authorized in writing by us. </li>
                            <li>The search results derived by use of our products and/or Services may contain data, information or content that is not ours, and you shall not use such data, information or content unless it has obtained permission from the owner of such content or are otherwise permitted by law.</li>
                            <li>Our names, logos, brands and other marks used by us from time to time are our trademarks and property. The appearance, layout, color scheme, and design of our website, app, software, services and products are protected trade dress. Save and except as provided herein, you do not receive any right or license to use the foregoing.</li>
                            <li>We may use and incorporate into our products and/or Services any suggestions or other feedback relating to our products and/or Services provided by you, without any payment or condition.</li>
                            <li>We may add features to and/or modify any of our products and/or Services from time to time, in order to improve our products and/or services and the user experience. Nothing in this Agreement shall prohibit us from making such changes to our products and/or Services. We reserve the right to provide some or all elements of our products and/or Services through use of third-party resources.</li>
                        </ul>
                    </p>
                </div>
                <div className=" mt-16 text-white">
                    <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">REPRESENTATIONS, WARRANTIES AND COVENANTS </h4>
                    <p className="2xl:text-xl md:text-lg text-sm opacity-60">
                        <ul className=" list-disc list-inside">
                           <li>You represent, warrant and covenant that: (a) you have the power and authority to enter into this Agreement; (b) you , being a natural person, is at least eighteen (18) years of age; (c) you will only access and use our products and/or Services in accordance with the terms of this Agreement; and (d) you will at all times be, in full compliance with all applicable laws, regulations, rules and ordinances regarding its business, the creation, use and maintenance, as applicable, of our products and/or Services, the user data and other data, including without limitation applicable data protection laws and export control laws, and otherwise as related to this Agreement and you performance hereunder.</li>
                           <li>Your access to our products and Services is conditioned upon your representations and warranties that he/she/it will not:
                            <ul>
                                <li>Undertake or abet in any criminal or tortuous activity, including child pornography, fraud, trafficking in obscene material, drug dealing, gambling, harassment, stalking, spamming, copyright infringement, patent infringement, or theft of trade secrets or other intellectual property rights;</li>
                                <li>Use our products and/or Services in violation of the terms of this Agreement or any laws;</li>
                                <li>Copy, modify, create a derivative work from, reverse engineer or reverse assemble our products and/or Services and/or software thereto, or otherwise attempt to discover any source code/algorithm or allow any third party to do so;</li>
                                <li>Sell, assign, sublicense, distribute, commercially exploit, grant a security interest in or otherwise transfer any right in, or make available to a third party, Our content or our Services in any way;</li>
                                <li>Use or launch any automated system, including without limitation, ‘robots’, ‘spiders’, or ‘offline readers’ that accesses our products and/or services in a manner that sends more request messages to our servers/systems in a given period of time than a human can reasonably produce in the same period by using a conventional on-line web/mobile browser/applications;</li>
                                <li>Use our products and/or Services in any manner that damages, disables, overburdens, or impairs any our servers, systems, website, or app or interferes with any other party’s use and enjoyment of our Services;</li>
                                <li>Attempt to gain unauthorized access to our products and/or Services or our servers, systems, platform, website, or application;</li>
                                <li>Access our products and/or Services by any means other than through the interface that is provided by us for using or accessing our products.</li>
                            </ul>
                           </li>
                        </ul>
                    </p>
                </div>
            </div>
            </div>
        </section>
    )
}
export default Terms;