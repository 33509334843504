import React, { useState, Suspense, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from "swiper";

import Platforms from "../components/Platforms";
import BuildTeamsnew from "../components/BuildTeamsnew";
import JourneyPolygan from "../components/JourneyPolygan";
import ElitesCardSlider from "../components/ElitesCardSlider";
import JourneyCards from "../components/JourneyCards";
import HomeBannerSlider from "../components/HomeBannerSlider";
import Partners from "../components/Partners";
import HomeNavbar from "../components/HomeNavbar";
import HomeNewsLetter from "../components/HomeNewsletter";
import Card3DModel from "../components/Card3DModel";
import HomeBanner from "./HomeBanner";
import BuildTeamsnew2 from "./BuildTeamsnew2";
import UserNFTcard from "../components/UserNFTcard";
// import Collectionfreemint from "../components/Home/collectionfreemint";

import p2eImg from "../assets/image/p2eGames.png";
import smallimage from "../assets/image/smallimage.png";
import HomeDetails from "../components/newHome/HomeDetails";
import FeaturedPlayers from "../components/newHome/FeaturedPlayers";
import NewsFeed from "../components/newHome/NewsFeed";
import TrendingGames from "../components/newHome/TrendingGames";
import OurJourney from "../components/newHome/OurJourney";
import TrueSocial from "../components/newHome/TrueSocial";
import OurPartners from "../components/newHome/OurPartners";
const Collectionfreemint = React.lazy(() => import("../components/Home/collectionfreemint"));
const collection = require("../assets/image/metamass.png");

const videomp4 = require("../../ui/assets/video/h3.mp4");
const image = require("../../ui/assets/image/bodyBg-min.png");

SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

export function Home() {
  const [open, setOpen] = useState<any>("1");
  const [numbers, setnumbers] = useState<number>(300);
  const [polygn, setpolygn] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = (event: any) => {
      if (window.scrollY > 3300) {
        setpolygn(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      {/* <video autoPlay loop muted playsInline className=" -z-10 video-frame blur-1px" poster={image}>
        <source src={videomp4} type="video/mp4" className=" " />
      </video> */}
      <HomeBanner />
      <HomeDetails />
      <FeaturedPlayers />
      <NewsFeed />
      <TrendingGames />
      <OurJourney />
      <OurPartners />
      <TrueSocial />

      {/* <Suspense fallback={<div className="h-60 container-1978 bg-zinc-800"></div>}>
        <Collectionfreemint />
      </Suspense> */}
      {/* <BuildTeamsnew2 /> */}

      {/* <ElitesCardSlider /> */}

      {/* <Suspense fallback={<div className="h-60 container-1978 bg-zinc-800"></div>}>
        {polygn ? <JourneyPolygan /> : ""}
      </Suspense> */}
      {/* <JourneyPolygan /> */}
      {/* <Platforms /> */}
      {/* <OurPartners /> */}
      {/* <HomeNewsLetter /> */}
      {/* <Partners/> */}
      {/* <JourneyCards/> */}
    </>
  );
}
export default Home;
