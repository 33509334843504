import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const logo = require("../../../ui/assets/image/logo.svg").default;
const betalogo = require("../../../ui/assets/image/beta.svg").default;
const Footerbg = require("../../../ui/assets/image/footerbg.svg").default;
const instaicon = require("../../../ui/assets/image/icons/instaicon.svg").default;
const telegramicon = require("../../../ui/assets/image/icons/telegramicon.svg").default;
const twittericon = require("../../../ui/assets/image/icons/twittericon.svg").default;
const linkdinicon = require("../../../ui/assets/image/icons/linkdinicon.svg").default;
const metaicon = require("../../../ui/assets/image/icons/metaicon.svg").default;
const Footer = () => {
  const [show,setshow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setshow(true)
    }, 2000);
  },[])
  return (
    <div className="2xl:px-[150px] md:px-[100px] px-4 py-[40px] bg-black w-full">
      <div className="grid md:grid-cols-3">
        <div className="md:hidden flex justify-center mb-7 relative">
          <a href="https://instagram.com/officialgamegods?igshid=YmMyMTA2M2Y=" target={"_blank"}>
            <img
              className="xl:w-24 w-14 sm:w-20 opacity-70 hover:opacity-100 cursor-pointer"
              alt="social img"
              src={instaicon}
            />
          </a>

          <a href="https://www.linkedin.com/company/officialgamegods/" target={"_blank"}>
            <img
              className="xl:w-24 w-14 sm:w-20 opacity-70 hover:opacity-100 cursor-pointer"
              alt="social img"
              src={linkdinicon}
            />
          </a>
          <a href="https://discord.gg/wTsdejamvf" target={"_blank"}>
            <img
              className="xl:w-24 w-14 sm:w-20 opacity-70 hover:opacity-100 cursor-pointer"
              alt="social img"
              src={metaicon}
            />
          </a>
          <a href="https://twitter.com/GameGods_gg?t=NG3-93S8EjesNzCld8SMPw&s=09" target={"_blank"}>
            <img
              className="xl:w-24 w-14 sm:w-20 opacity-70 hover:opacity-100 cursor-pointer"
              alt="social img"
              src={twittericon}
            />
          </a>
          {/* <a href="" target={"_blank"}>
            <img
              className="xl:w-24 w-14 sm:w-20 opacity-70 hover:opacity-100 cursor-pointer"
              src={telegramicon}
              alt="social icon"
            />
          </a> */}
        </div>
        <div className="md:grid md:grid-cols-2 flex flex-wrap-reverse uppercase">
          <ul className="w-full text-white font-poppins font-normal text-sm opacity-60 leading-10 md:hidden flex flex-wrap justify-around">
            {/* <li>COOKIES</li> */}
            {/* <li>FAQ</li> */}
            <li onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}>
              <Link to="/privacy">PRIVACY POLICY</Link>
            </li>
            <li onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}>
              <Link to="/terms">TERMS AND CONDITION</Link>
            </li>
            {/* <li>CONTACT US</li> */}
            {/* <li>ABOUT US</li> */}
          </ul>
          <ul className="w-full text-green font-poppins font-normal text-sm leading-10 md:block flex flex-wrap justify-around">
            <li>
              <a className=" w-fit border-b border-black-bg hover:border-green cursor-pointer" href="https://hefty.games/" target="_blank">MARKETPLACE</a>
            </li>
            <li>
              <a href="https://app.gamegods.com/" className=" w-fit border-b border-black-bg hover:border-green cursor-pointer">REFER FRIENDS</a>
            </li>
            <li>
              <a href="https://app.gamegods.com/" className=" w-fit border-b border-black-bg hover:border-green cursor-pointer">REWARDS</a>
            </li>
            <li className=" hidden md:block" onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}>
              <Link className=" w-fit border-b border-black-bg hover:border-green cursor-pointer" to="/privacy">PRIVACY POLICY</Link>
            </li>
            <li className=" hidden md:block" onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}>
              <Link className=" w-fit border-b border-black-bg hover:border-green cursor-pointer" to="/terms">TERMS AND CONDITION</Link>
            </li>
          </ul>
        </div>
        <div>
          <div
            // data-aos="fade-in"
            className=" md:flex justify-center hidden mb-16"
          >
            <a href="https://instagram.com/officialgamegods?igshid=YmMyMTA2M2Y=" target={"_blank"}>
              <img
                className="xl:w-24 w-10 sm:w-20 opacity-70 hover:opacity-100 cursor-pointer"
                alt="social img"
                src={instaicon}
              />
            </a>

            <a href="https://www.linkedin.com/company/officialgamegods/" target={"_blank"}>
              <img
                className="xl:w-24 w-10 sm:w-20 opacity-70 hover:opacity-100 cursor-pointer"
                alt="social img"
                src={linkdinicon}
              />
            </a>
            <a href="https://discord.gg/wTsdejamvf" target={"_blank"}>
              <img
                className="xl:w-24 w-10 sm:w-20 opacity-70 hover:opacity-100 cursor-pointer"
                alt="social img"
                src={metaicon}
              />
            </a>
            <a href="https://twitter.com/GameGods_gg?t=NG3-93S8EjesNzCld8SMPw&s=09" target={"_blank"}>
              <img
                className="xl:w-24 w-10 sm:w-20 opacity-70 hover:opacity-100 cursor-pointer"
                alt="social img"
                src={twittericon}
              />
            </a>
            {/* <a href="" target={"_blank"}>
              <img
                className="xl:w-24 w-10 sm:w-20 opacity-70 hover:opacity-100 cursor-pointer"
                src={telegramicon}
                alt="social icon"
              />
            </a> */}
          </div>
          <div className="w-full flex items-center justify-center md:justify-center bg-black py-5 relative">
            <img src={betalogo} />
            {/* <div className="text-center absolute right-0 w-[38%] md:hidden">
              <Link
                to=""
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                <svg className="m-auto w-[30%]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 96" fill="none">
                  <path
                    d="M14.2672 47.9715L31.191 18.7167L65.089 18.7457L82.0632 48.0295L65.1395 77.2843L31.2415 77.2553L14.2672 47.9715Z"
                    fill="#1DF66A"
                    stroke="url(#paint0_linear_6643_1802)"
                    stroke-width="2.34047"
                  />
                  <path
                    d="M48.1632 59.888L48.1639 36.0617M48.1639 36.0617L33.9414 50.2842M48.1639 36.0617L62.365 50.2842"
                    stroke="black"
                    stroke-width="2"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_6643_1802"
                      x1="30.5134"
                      y1="17.5476"
                      x2="61.5759"
                      y2="80.1493"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#1DF66A" />
                      <stop offset="0.228794" stop-color="#FCFCFC" stop-opacity="0.63" />
                      <stop offset="0.68452" stop-color="#505853" />
                      <stop offset="1" stop-color="#14AF4B" />
                    </linearGradient>
                  </defs>
                </svg>
                <p className="font-poppins text-[10px] text-white">SCROLL TO THE TOP</p>
              </Link>
            </div> */}
          </div>
        </div>
        <div className="md:flex hidden md:justify-end justify-center w-full">
          <div className="text-center md:mt-4">
            <Link
              to=""
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              <svg className="mx-auto w-auto" width='70' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 96" fill="none">
                <path
                  d="M14.2672 47.9715L31.191 18.7167L65.089 18.7457L82.0632 48.0295L65.1395 77.2843L31.2415 77.2553L14.2672 47.9715Z"
                  fill="#1DF66A"
                  stroke="url(#paint0_linear_6643_1802)"
                  stroke-width="2.34047"
                />
                <path
                  d="M48.1632 59.888L48.1639 36.0617M48.1639 36.0617L33.9414 50.2842M48.1639 36.0617L62.365 50.2842"
                  stroke="black"
                  stroke-width="2"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_6643_1802"
                    x1="30.5134"
                    y1="17.5476"
                    x2="61.5759"
                    y2="80.1493"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#1DF66A" />
                    <stop offset="0.228794" stop-color="#FCFCFC" stop-opacity="0.63" />
                    <stop offset="0.68452" stop-color="#505853" />
                    <stop offset="1" stop-color="#14AF4B" />
                  </linearGradient>
                </defs>
              </svg>
              <p className="font-poppins text-sm text-white w-fit mx-auto">Scroll to the top</p>
            </Link>
          </div>
        </div>
      </div>

      {/* Cookie Policy popup */}
      {show
      ?
      <div className="fixed z-999 bottom-0 left-0 w-full">
        <div className="2xl:px-[150px] md:px-[100px] px-4 py-[40px]">
          <div className="bg-green p-[1px] clip-pathcorner">
            <div className="clip-pathcorner p-6 bg-black-bg">
              <p className=" text-[#fff] md:text-3xl text-base font-bold font-Orbitron text uppercase ">Cookie Policy</p>
              <div className="flex flex-wrap">
                <div className="2xl:w-[70%] xl:w-[65%] lg:w-[55%] w-[100%]">
                  <p className=" font-light font-poppins 2xl:text-xl md:text-lg text-xs opacity-60 text-[#fff]">Avalon Tech Inc. is an US based tech startup company seeking to create a social network for gamers worldwide via its application and website ‘www.gamegods.com’.</p>
                </div>
                <div className="2xl:w-[30%] xl:w-[35%] lg:w-[45%] w-[100%] lg:justify-center justify-between flex items-center md:flex-row flex-row-reverse lg:mt-0 mt-8">
                  <button onClick={() => setshow(false)} className="text-xxs sm:text-sm lg:text-lg uppercase py-1 xs:py-1 sm:py-2 px-3 font-Orbitron sm:px-4 duration-150 text-black-bg font-bold btn-cut-corner-2">Accept</button>
                  <Link className=" md:ml-8 text-[#999999] underline font-Orbitron font-light md:text-[22px] text-sm" to="">Learn More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      null
      }
      
    </div>
  );
};
export default Footer;
