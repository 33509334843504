import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { setupParse } from "./pkg/parse";
import { LandingPage } from "./ui/pages/HomeLanding";

import { BrowserRouter as Router } from "react-router-dom";
import Header from "./ui/components/common/Header";
import Footer from "./ui/components/common/Footer";
import ReactGA from "react-ga";

import Routess from "./routes";
import HomeNavbar from "./ui/components/HomeNavbar";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: true,
      startEvent: "load",
    });
    AOS.refreshHard();
  }, []);

  return (
    <Router>
      <HomeNavbar />

      {/* <Header /> */}
      <Routess />
      <Footer />
    </Router>
  );
}

ReactGA.initialize("G-VM2PE9D16Z");

setupParse();

export default App;
