import { Error, Query, Object } from 'parse';
import { Game } from '../model/game';
import { Platform } from '../model/platform';

export interface IGameAPI {
    createGame(
        name: string,
        platforms: Platform[],
        gameName: string,
    ): Promise<Game | Error>

    addGamePlatformRelation(
        game: Game,
        platforms: Platform[]
    ): Promise<Game | Error>

    checkGameNameFree(): Promise<boolean | Error>

    getGames(include: boolean): Promise<Game[] | Error>

    addTopPlatform(game: Game, platform: Platform): Promise<Game | Error>
}




export class GameAPI implements IGameAPI {


    async createGame(name: string, platforms: Platform[], gameName: string): Promise<Game | Error> {
        try {
            let game = new Game()
            game.set('name', name)
            game.set('gameName', gameName)
            game = await game.save()
            const updatedGame = await this.addGamePlatformRelation(game, platforms)
            if (updatedGame instanceof Error) return updatedGame
            return updatedGame
        } catch (e) {
            return e as Error
        }
    }

    async addGamePlatformRelation(game: Game, platforms: Platform[]): Promise<Game | Error> {
        try {
            for (const platform of platforms) {
                game.attributes.platforms.add(platform)
                platform.attributes.games.add(game)
                platform.increment('gamesCount')

                await game.save()
                await platform.save()
            }
            return game
        } catch (e) {
            return e as Error
        }
    }

    async getGames(include: boolean): Promise<Error | Game[]> {
        try {
            var query = new Query(Game);
            // query.select('gameName')
            // if (include) query.include('topPlatform')
            const games = await query.findAll()
            return games as Game[]
        } catch (e) {
            return e as Error
        }
    }

    async addTopPlatform(game: Game, platform: Platform): Promise<Game | Error> {
        try {
            game.set('topPlatform', platform.toPointer())
            game = await game.save()
            // game.attributes.bannerImage!
            return game
        } catch (e) {
            return e as Error
        }
    }

    checkGameNameFree(): Promise<boolean | Error> {
        throw ''
    }
}
