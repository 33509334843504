import { createRelation } from '../pkg/parse';
import { Game } from './game';
import { Relation, Object, Pointer } from "parse"

interface PlatformFields {
    name: string
    games: Relation<Game>
    gamesCount: number
    platformName: string
}

export class Platform extends Object<PlatformFields> {
    constructor(fields = {
        name: '',
        platformName: '',
        games: createRelation<Game>('Game', 'games'),
        gamesCount: 0,
    }) {
        super('Platform', fields);
    }
}

Object.registerSubclass('Platform', Platform)



