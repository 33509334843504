import { GameAPI } from "../api/game";
import { LoginAPI } from "../api/login";
import { PlatformAPI } from "../api/platform";
import parse, { Relation, Object, Pointer } from "parse"



export function createRelation<T extends Object>(
    modelName: string,
    fieldName: string
): Relation<T> {
    const relation = new Relation<T>(undefined, fieldName)
    relation.targetClassName = modelName

    return relation
}

export function createPointer(): Pointer {
    return {
        __type: 'POINTER',
        objectId: '',
        className: '',
    }
}

export function setupParse() {

    parse.initialize(
        "BuqsuIbtrqX3rtG61WLpkMi1XQg52fTKLjruuQ0Z",
        "Iwo03TboDdjAcNWi4V6fiTAcIP35i0DCzDp7csSB"
    );

    parse.serverURL = 'https://parseapi.back4app.com';
    // User.logInWith()
    // experiments()
}

export async function experiments() {
    const gamesAPI = new GameAPI()
    const platformAPI = new PlatformAPI()
    const loginAPI = new LoginAPI()


    console.log('========> 1')
    const resp = await loginAPI.login('simran', '')


    // const p1 = await platformAPI.createPlatform('PS5', 'p1')
    // if (p1 instanceof Error) return
    // console.log('========> 1.5')

    // const p2 = await platformAPI.createPlatform('PS4', 'p2')
    // if (p2 instanceof Error) return

    // const p3 = await platformAPI.createPlatform('PS3', 'p3')
    // if (p3 instanceof Error) return

    // const p4 = await platformAPI.createPlatform('PS2', 'p4')
    // if (p4 instanceof Error) return

    // const p5 = await platformAPI.createPlatform('PS1', 'p5')
    // if (p5 instanceof Error) return
    // console.log('========> 2')

    // const platforms = await platformAPI.getPlatforms()
    // if (platforms instanceof Error) return
    // console.log('========> 2.5')
    // for (const platform of platforms) {
    //     const obj = platform.toJSON()
    //     console.log('========> platform::: ' + JSON.stringify(obj))
    // }
    // console.log('========> 3')

    // const g1 = await gamesAPI.createGame('FIFA 1', [p5, p4], 'g1')
    // if (g1 instanceof Error) return
    // console.log('========> 4')

    // const g2 = await gamesAPI.createGame('FIFA 2', [p5, p4, p3], 'g1')
    // if (g2 instanceof Error) return
    // console.log('========> 5')

    // const g3 = await gamesAPI.createGame('FIFA 3', [p5, p4, p2], 'g2')
    // if (g3 instanceof Error) return
    // console.log('========> 6')

    // const g4 = await gamesAPI.createGame('FIFA 4', [p2, p4, p1], 'g3')
    // if (g4 instanceof Error) return
    // console.log('========> 7')

    // const g5 = await gamesAPI.createGame('FIFA 5', [p5, p4, p3, p2, p1], 'g4')
    // if (g5 instanceof Error) return
    // console.log('========> 8')

    // let games = await gamesAPI.getGames(false)
    // if (games instanceof Error) return

    // for (const game of games) {
    //     const obj = game.toJSON()
    //     console.log('========> game::: ' + JSON.stringify(obj))
    //     // const pl = game.get('platforms')[0]
    //     // console.log('========> ppp::: ' + pl.attributes.name)

    //     // console.log('========> platformname::: ' + JSON.stringify(game.get('platforms').toJSON()))

    // }
    // console.log('========> 9')

    // const g = gamesAPI.addTopPlatform(games[0], platforms[0])

    // console.log('========> 10')
    // games = await gamesAPI.getGames(false)
    // if (games instanceof Error) return

    // for (const game of games) {
    //     const obj = game.toJSON()
    //     console.log('========> game::: ' + JSON.stringify(obj))
    // }
    // console.log('========> 11')



    // console.log('========> 12')
    // games = await gamesAPI.getGames(true)
    // if (games instanceof Error) return

    // for (const game of games) {
    //     const obj = game.toJSON()
    //     console.log('========> game::: ' + JSON.stringify(obj))
    // }
    // console.log('========> 13')

}