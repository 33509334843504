const Privacy = () => {
    return(
        <section className=" overflow-hidden pb-10 pt-20 md:pt-40 relative">
            <div className="width-1920">
            <div className=" text-center w-full flex justify-center items-center flex-col gap-2 sm:gap-5 text-white">
                <p className=" w-fit absolute opacity-5 z-0 top-0 left-[50%] right-[50%] translate-x-[-50%] 2xl:text-[200px] md:text-[160px] text-[100px] font-Orbitron whitespace-nowrap">Our Privacy Policy</p>
                <h1 className="relative z-1 font-Orbitron font-semibold 2xl:text-7xl lg:text-6xl md:text-5xl text-2xl">Our <span className=" text-green">Privacy Policy</span></h1>
            </div>
            <div className=" mt-20 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase"> About Us </h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">Avalon Tech Inc. is an US based tech startup company seeking to create a social network for gamers worldwide via its application and website ‘www.gamegods.com’. 
                Avalon Tech Inc. and our subsidiaries (“we,” “us” or “Avalon”) are a leading provider of online tools for gamers for the purpose of social networking through our brand GameGods. We cater to gamers and game enthusiasts globally. We care about protecting the personal information of our clients and participants who use our website, app or services (collectively, our “Users”). This privacy policy provides details about how your personal information is collected, shared and used by us. To learn more about Avalon Tech, visit: www.gamegods.com.
                If you have any questions about this privacy policy or the practices described herein, you may contact info@gamegods.com or 7209 Lancaster Pike Suit 4-1086, Hockessin, DE 19707. For the purposes of the European Union’s General Data Protection Regulation 2016/679, or GDPR Avalon Tech Inc. and each of the brands for which you have signed up for a service are the data controller, and we have no European local representatives.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase"> Information Covered by this Privacy Policy </h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">This privacy policy covers personal information, including any information we collect, use and share from you, as described further below. This privacy policy applies to all websites in the Avalon corporate family, our services, and our mobile applications (collectively, the “Services”). This privacy policy does not cover how our Users may use or share data that they collect using our Services.
                When you purchase a Service from us, your personal information will be collected, used, and shared consistent with the provisions of this privacy policy as well as the following addendums related to particular services offered by Avalon, which are also a part of this privacy policy:
                Addendum (reserved for future use)
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase"> Information We Collect from You </h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">In the course of your use of the Services, we obtain the following information about you as described below. We collect this data for the purposes described under <i><strong>“How We Use Your Information”</strong></i>.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase"> Signing Up for Services; User Information </h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">User information is:</p>
                <ul className=" opacity-60">
                    <li><span className="text-green">o</span> Information that you provide us with during any registration process such as your name, email address, phone number, billing address, geographic location, gender, relationship status, and gaming information i.e. games you like, platform used for the games, tournaments you have won, membership to an eSports team; and</li>
                    <li><span className="text-green">o</span> Information when you write or email us for support or otherwise or when you use our Services; and</li>
                    <li><span className="text-green">o</span> Payment information that you provide to us (by way of our Services or otherwise) including credit card data.</li>
                    <li><span className="text-green">o</span> Also, while you are browsing our website(s) without providing us with any personal information, some information about your visit will be gathered and stored such as your IP address, the type of browser and operating system you are using, the date and time you accessed our website, the pages you visited, and in case you’ve been linked to our website from another website, the address of linking website. This information will not identify you personally and will not be linked back to you.</li>
                </ul>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">As our website works on invite only modus, you as a user need to provide information prior to any registration process or account creation. These include name, phone number, email, gaming information as mentioned above and why you want to join.As our website works on invite only modus, you as a user need to provide information prior to any registration process or account creation. These include name, phone number, email, gaming information as mentioned above and why you want to join.</p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase"> Use of Services, Website Visits and Support </h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">Data relating to your online activity on our websites with respect to our Services, including the following:
                <ul className=" list-disc list-inside">
                    <li>IP address,</li>
                    <li>browser type and version,</li>
                    <li>geographic location,</li>
                    <li>pages you view (first time users requesting invite will see only invite request page, invitation accepted users will see the homepage with option to register, and registered users will see the rest of the pages of the website)</li>
                    <li>how you got to our Services and any links you click on to leave our Services,</li>
                    <li>when you update your information, communicate with us,</li>
                    <li>metadata about your use of our Services and your emails you send,</li>
                    <li>your interactions with any videos we offer,</li>
                    <li>your interactions with other users via the third-party chat cloud server,</li>
                    <li>issues you encounter requiring our support or assistance,</li>
                    <li>any device or other method of communication you use to interact with the Services,</li>
                </ul>
                We store this data we collect in a secured database within our infrastructure only accessible by administrators via a secured admin panel. This data is collected for analytical purposes only.
                <br/>
                Apart from above mentioned internal database, the user data is also shared on third party chat cloud server so that users can seamlessly chat with each other using a single login. We have a provision to delete chat history on users’ request. The third-party entity with whom we are sharing client information is cometchat, which hosts its chat on a separate cloud server.
                We do not use telephone to communicate with our users. The preferred method for all our conversations with our users is email and all emails are stored on our secure support email inbox.
                <br/>
                <h4 className="mt-16 font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase"> Social Media </h4>
                <ul className=" list-disc list-inside">
                    <li>Information from and to third-party social networking sites,</li>
                </ul>
                <h4 className="mt-16 font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase"> Other Sources </h4>
                <ul className=" list-disc list-inside">
                    <li>Information you provide to us at events,</li>
                    <li>Information you provide to us in surveys,</li>
                    <li>Information that is publicly available; and</li>
                    <li>Information you consent to us receiving from third parties.</li>
                </ul>
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase"> How We Use Your Information </h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">We use the information we collect, both on its own and combined with any other information we collect about you, for the following purposes:
                <ul className=" list-disc list-inside">
                    <li>Client information is used to provide acceptance to the invite request;</li>
                    <li>Once accepted the information is used to provide better experience on the site by providing relevant news, friend suggestions, games, events etc.;</li>
                    <li>For better café locator tracking;</li>
                    <li>To send browser notifications;</li>
                    <li>To provide the requested Services to you;</li>
                    <li>To provide you with useful content;</li>
                    <li>To ensure the proper functioning of our Services;</li>
                    <li>To offer and improve our Services;</li>
                    <li>To provide you with requested information or technical support;</li>
                    <li>To facilitate your movement through our website and your use of our Services;</li>
                    <li>To do a better job of advertising and marketing our Services (our use of your information to do this is performed with your consent where required by applicable law);</li>
                    <li>To diagnose problems with our servers or our Services;</li>
                    <li>In connection with our security programs;</li>
                    <li>To administer our websites;</li>
                    <li>To communicate with you;</li>
                    <li>To assist us in offering you a personalized experience or otherwise tailor our Services to you; and as otherwise described in this privacy policy.</li>
                </ul>
                We also use the information we receive in aggregated and anonymized formats to produce statistical and analytical reports to better analyze our services.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Sharing of Information </h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">We do not share any client info except login information for seamless connection purposes into the internal chat system that is hosted on third-party cloud servers’ of cometchat.
                <h4 className="mt-16 font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase"> Authorized Users </h4>
                We share information about authorized users only for legitimate purposes consistent with this privacy policy, including servicing your account.
                <h4 className="mt-16 font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase"> Corporate Reorganizations </h4>
                If we are involved in a merger, acquisition, a sale of all or a substantial portion of our assets, or other similar sale transaction, your information will be transferred as part of that transaction. We will notify you by email and/or a prominent notice on our website of any such transfer and any choices you may have regarding your information.
                <h4 className="mt-16 font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase"> Legal Process </h4>
                Finally, if legally required to do so, or if we have a good faith belief that such disclosure is reasonably necessary, we may disclose your personal information to courts of law, law enforcement authorities and other relevant third parties, such as internet service providers, to conduct an investigation, respond to a third party or law enforcement subpoena or court order, bring legal action, prevent harm to others or pursue other relief when you or a third party are or may be:
                <br/>	
                <ul>
                    <li><span className="text-green">o</span> violating our terms and conditions of use;</li>
                    <li><span className="text-green">o</span> causing injury or other harm to, or otherwise violating the property or other legal rights, of us, other users, or third parties; or</li>
                    <li><span className="text-green">o</span> violating federal, state, local, or other applicable law.</li>
                </ul>
                This disclosure can include transferring your information to the U.S. and other countries outside the European Economic Area. To the extent we are legally permitted to do so, it is our policy to notify you in the event that we are required to provide your personal information to third parties in connection with a subpoena.
               </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Security</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">The transmission of information via the internet, email or text message is not completely secure. Although we will do our best to protect your personal information, we cannot guarantee the security of your information transmitted through the Services or over email; any transmission is at your own risk. Once we have received your information, we will take appropriate technical and organizational measures to safeguard your personal information against loss, theft and unauthorized use, access or modification.
                <br/>
                When we collect information, we protect its transmission through the use of encryption such as the Transport Layer Security (TLS) protocol.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Tracking Technologies and Online Advertising</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">We use cookies, scripts such as Google analytics scripts, and other similar technologies in the course of our business. Information about the technologies we use, why we use them and how you can control them can be found in our Cookie Policy.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Choice/Opt-out</h4>
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Email</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">You always have the opportunity to opt out of our email communications by emailing us at info@gamegods.com. Some communications from us are considered transactional or service communications (for example, important account notifications), and your account(s) for Avalon services are provided to you upon the condition that you receive these communications from us. You must cancel your account for Avalon services, as applicable, if you do not wish to receive any transactional or service communications. 
                <h4 className="mt-16 font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Cookies</h4>
                Users will be provided with an option to opt in/out at the start of the website.
                <h4 className="mt-16 font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Information from Third Parties</h4>
                To manage the information we receive about you from a social networking site or other third parties (if applicable), you will need to follow the instructions from that party for updating your information and changing your privacy settings, where available. The information we collect is covered by this privacy policy and the information the third-party collects is subject to such third party’s privacy practices. Privacy choices you have made on any third-party site will not apply to our use of the information we have collected directly through our Services.
                <h4 className="mt-16 font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Unauthorized Accounts</h4>
                If an account was created without your knowledge or authorization, please contact us to request removal of the account.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Retention of Personal Information</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">We retain your personal information to provide services to you and as otherwise necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. We will retain your personal information for no more than seven years following the later of (i) the date on which you terminate your use of the Services or (ii) September 2018, unless we are otherwise required by law or regulation to retain your personal information for longer.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Legal Basis for Processing Your Information</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">Depending on what information we collect from you and how we collect it, we rely on various grounds for doing processing your information under the GDPR, including the following reasons:
                <ul className=" list-disc list-inside">
                    <li>In order to administer our contractual relationship, including setting up your requested Services;</li>
                    <li>Because it is in our legitimate interest to run a successful and efficient business and provide you with the Services and other useful content;</li>
                    <li>In order to fulfill any legal obligations we may have to collect this information from you; and/or</li>
                    <li>Because you have provided your consent for us to do so.</li>
                </ul>
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Your Rights</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">Where the European Union’s General Data Protection Regulation 2016/679, or GDPR, applies, in certain circumstances and subject to data processing agreements, you have rights in relation to the personal information we hold about you. We set out below an outline of those rights and how to exercise those rights. Please note that we will require you to verify your identity before responding to any requests to exercise your rights by providing details only known to the account holder. Please note that for each of the rights below we may have valid legal reasons to refuse your request, in such instances we will let you know if that is the case.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Access</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">You have the right to know whether we process personal information about you, and if we do, to access data we hold about you and certain information about how we use it and who we share it with.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Portability</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">You have the right to receive a subset of the personal information you provide us if we process it on the legal bases of our contract with you or with your consent in a structured, commonly used and machine-readable format and a right to request that we transfer such personal information to another party. If you wish for us to transfer the personal information to another party, please ensure you detail that party and note that we can only do so where it is technically feasible. We are not responsible for the security of the personal information or its processing once received by the third party.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Correction</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">You have the right to require us to correct any personal information held about you that is inaccurate and have incomplete data completed. Where you request correction, please explain in detail why you believe the personal information we hold about you to be inaccurate or incomplete so that we can assess whether a correction is required. Please note that while we assess whether the personal information we hold about you is inaccurate or incomplete, you may exercise your right to restrict our processing of the applicable data as described below.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Erasure</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">You may request that we erase the personal information we hold about you in the following circumstances:
                <ul>
                    <li><span className="text-green">o</span> where you believe it is no longer necessary for us to hold the personal information;</li>
                    <li><span className="text-green">o</span> we are processing it on the basis of your consent and you wish to withdraw your consent;</li>
                    <li><span className="text-green">o</span> we are processing your data on the basis of our legitimate interest and you object to such processing;</li>
                    <li><span className="text-green">o</span> you no longer wish us to use your data; or</li>
                    <li><span className="text-green">o</span> you believe we are unlawfully processing your data.</li>
                </ul>
                Please provide as much detail as possible on your reasons for the request to assist us in determining whether you have a valid basis for erasure.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Restriction of Processing to Storage Only</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">You have a right to require us to stop processing the personal information we hold about you other than for storage purposes in the following circumstances:
                <ul>
                    <li><span className="text-green">o</span> You believe the personal information is not accurate for the period it takes for us to verify whether the data is accurate;</li>
                    <li><span className="text-green">o</span> We wish to erase the personal information as the processing we are doing is unlawful, but you want us to simply restrict the use of that data;</li>
                    <li><span className="text-green">o</span> We no longer need the personal information for the purposes of the processing, but you require us to retain the data for the establishment, exercise or defense of legal claims; or</li>
                    <li><span className="text-green">o</span> You have objected to us processing personal information we hold about you on the basis of our legitimate interest and you wish us to stop processing the personal information while we determine whether there is an overriding interest in us retaining such personal information.</li>
       
                </ul>
                Please provide as much detail as possible on your reasons for the request to assist us in determining whether you have a valid basis for erasure.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Objection</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">You have the right to object to our processing of data about you and we will consider your request. Please provide us with detail as to your reasoning so that we can assess whether there is a compelling overriding interest in us continuing to process such data or we need to process it in relation to legal claims.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Withdrawal of Consent</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">Where you have provided your consent to us processing your personal data, you can withdraw your consent at any time by contacting us by email at
                <a href = "mailto: info@gamegods.com"> info@gamegods.com</a>.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Third Party Links and Information Collection and Use by Our Users</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">Some of our Services provide links to other websites. Because we do not control the information policies or practices of these third-party sites, you should review their privacy policies to learn about how they collect and use personal information.</p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Children</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">Our Services are not directed to persons under 13. We do not knowingly collect personal information from children under 13. If a parent or guardian becomes aware that his or her child has provided us with personal information without such parent or guardian’s consent, he or she should contact us. If we become aware that a child under 13 has provided us with personal information, we will delete such information from our files. Any children below the ages of 13 will not be allowed to register.</p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Data Transfer</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">In order for us to provide the Services to you, your personal information will be transferred to, and stored at/processed in India. Your personal data is also processed by staff operating outside the European Economic Area (EEA), such as in India and the USA, who work for us or for one of our suppliers. We will take all steps reasonably necessary to ensure that your personal data is treated securely and in accordance with this policy.
                <br/>
                For transfers of personal information within the company, such transfer will be under the Commission’s model contracts for the transfer of personal data to third countries (i.e., the standard contractual clauses), pursuant to Decision 2004/915/EC and 2010/87/EU.
                <br/>
                For transfers of data to third parties, such transfers will either (i) be under the Commission’s model contracts for the transfer of personal data to third countries (i.e., the standard contractual clauses), pursuant to Decision 2004/915/EC and 2010/87/EU; or (ii) rely on the EU-US Privacy Shield Framework.
                <br/>
                You can contact the Data Protection Officer listed below to obtain a copy of the data transfer agreement or more information regarding the relevant safeguard we put in place. For more information about the EU-US Privacy Shield Framework, please visit the U.S. Department of Commerce’s Privacy Shield website.
                </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Complaints</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">In the event that you wish to make a complaint about how we process your personal information, please contact our Data Protection Officer at info@gamegods.com and we will try to deal with your request. </p>
            </div>

            <div className=" mt-16 text-white">
                <h4 className=" font-Orbitron text-green font-light text-base md:text-2xl lg:text-4xl 2xl:text-5xl mb-3 uppercase">Notification of Changes</h4>
                <p className="2xl:text-xl md:text-lg text-sm opacity-60">We reserve the right to modify this privacy policy at any time, so please review it frequently. If we decide to change this privacy policy in any material way, we will notify you here prior to the change becoming effective. In all cases, your continued use of any Services constitutes acceptance to any such changes.</p>
            </div>


         

         

        </div>
        </section>
    )
}
export default Privacy;